import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class AddCLI extends Component {
	constructor(props) {
		super(props);

		this.baseFileInput = React.createRef();
		let sitePath;
		let filePath;
		if (props.app.mode === 'dev') {
			sitePath = 'http://core-php.local';
			filePath = 'http://core-php.local/';
		} else {
			const protocol = window.location.protocol;
			const slashes = protocol.concat("//");
			sitePath = slashes.concat(window.location.hostname);
		}
		this.state = {

			baseFileInput: this.baseFileInput,
			parentId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			senderIdList: [],
			userList: [],
			cliAdded: false,
			allocated: 0,
			senderId: ''
		}

	}

	componentDidMount() {
		const apiPath = this.state.apiPath;
		this.getUsers();
		let apiUrl = '';

		// Get Sender Id List
		if (this.props.app.role === 'ROLE_SADMIN') {
			apiUrl = apiPath + '/api/voicemail/cli/all';
			this.state.allocated = 1;
		}
		else if (this.props.app.role === 'ROLE_ADMIN') {
			apiUrl = apiPath + '/api/voicemail/cli/allocated/' + this.props.app.userId;
			this.state.allocated = 2;
		}
		Fetcher.get(apiUrl)
			.then(res => {
				this.setState({
					senderIdList: res
				})
			})
			.catch(err => { console.log('Error in fetching Sender Id\'s', err) });
	}

	getUsers = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/voicemail/users/' + userId)
			.then(res => {
				this.setState({
					userList: res
				})
			})
			.catch(err => { console.log('Error in fetching Users List', err) });
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	handleSelect = function (selectedItems) {
		const cliSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			cliSelected.push(selectedItems[i].value);
		}
		this.setState({
			senderId: cliSelected.toString()
		});
	}

	setMessageShow = (status) => {
		const cliAdded = this.state.cliAdded;
		this.setState({
			redirectToSummary: cliAdded
		});
	}

	// Add CLI
	handleAddCLISubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);
		const data = {
			"userId": formData.userId,
			"cliList": formData.senderId,
			"parent": this.state.parentId,
			"allocated": this.state.allocated
		}
		this.addNewCLI(data);
	}

	addNewCLI = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/voicemail/cli', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let cliAdded = false;
				if (res.message === 'Parameters Incorrect') {
					modalHeading = 'Error';
				} else {
					Notify.notify(res.message);
					modalHeading = 'Success';
					cliAdded = true;
				}
				this.setState({
					cliAdded: cliAdded
				})

			})
			.catch(err => {
				console.log('Error in Allocating CLI', err);
				Notify.notify('Error in Allocating CLI');
			});
	}

	render() {

		if (this.state.cliAdded === true) {
			return <Redirect to={'/cli-list/'} />
		}

		const senderIdList = cloneDeep(this.state.senderIdList);
		const userIdList = cloneDeep(this.state.userList);

		let submitButtonlabel = 'Allocate CLI';

		let senderIdDropdown = '';
		if (!isEmpty(senderIdList) && (!('error' in senderIdList))) {
			senderIdDropdown = Array.isArray(senderIdList) ? senderIdList.map((obj, index) => {
				return <option value={obj.cliId} key={`senderid${index}`} >{obj.cli}</option>
			}) : <option value="" >No CLI found</option>

		} else {
			senderIdDropdown = <option value="" >No CLI found</option>
		}

		let userIdDropdown = '';
		if (!isEmpty(userIdList) && (!('error' in userIdList))) {
			userIdDropdown = Array.isArray(userIdList) ? userIdList.map((obj, index) => {
				return <option value={obj.userId} key={`userid${index}`} >{obj.name}</option>
			}) : <option value="" >No User found</option>

		} else {
			userIdDropdown = <option value="" >No User found</option>
		}


		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddCLISubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Allocate CLI</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={2}>
										<Form.Group controlId="sender-id">
											<Form.Label>From : Sender ID</Form.Label>
											<Form.Control required name="senderId" onChange={(e) => { this.handleSelect(e.target.selectedOptions) }} multiple as="select">
												<option value="">Select Sender Id</option>
												{senderIdDropdown}
											</Form.Control>
										</Form.Group>
									</Col>
									<Col sm={2}>
										<Form.Group controlId="user-id">
											<Form.Label>Users</Form.Label>
											<Form.Control required name="userId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select User</option>
												{userIdDropdown}
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default AddCLI;