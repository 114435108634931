import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, Card, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../Helpers/fetcher.js";
import { Notify } from "../../Helpers/notification.js";
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { cloneDeep, isEmpty } from 'lodash';
import overlayFactory from 'react-bootstrap-table2-overlay';
import "./index.css";

class UserManagement extends Component {
	constructor(props) {

		super(props);
		this.state = {
			userList: [],
			adminCredits: 0,
			creditsToAdd: 0,
			creditsToRemove: 0,
			confirmDelete: false,
			selectedUserId: 0,
			showAddCreditModal: false,
			showRemoveCreditModal: false,
			password: '',
			confirmPassword: '',
			showPwdModal: false,
			selectedAllocateApiCliUserId : '',
			confirmAllocateApiCliUserId : false,
			manualAllocation : false,
			bulkAllocation : false,
			cliList : [],
			allocateApiCliId : '',
			cliRange : '',
			cliId : '',
			allocatedCliList: [],
			allocatedCliListFlag: false,
			allocatedClisIds : '',
			confirmRemoveAllocatedCli: false,
			loading: true
		}
	}
	componentDidMount() {
		this.getUsers();
		this.getCli();
	}

	confirmAllocatedCliRemove = (status) => {
		this.setState({
			confirmRemoveAllocatedCli: status
		})
	}

	getUsers = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/voicemail/user/' + userId)
			.then(res => {
				this.setState({
					userList: res.users,
					adminCredits: res.adminCredits,
					showAddCreditModal: false,
					showRemoveCreditModal: false,
					showPwdModal: false,
					confirmDelete: false,
					loading: false
				})
			})
			.catch(err => {
				console.log('Error in fetching Users List', err);
			});
	}

	confirmDelete = (status, userId) => {
		this.setState({
			confirmDelete: status,
			selectedUserId: userId
		});
	}

	deleteUser = () => {
		const userId = this.state.selectedUserId;
		const data = {
			parent: this.props.app.userId,
		}

		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/user/' + userId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Deleting User', err)
				Notify.notify('Error in Deleting User');
			});
	}
	showPassword = (userId) => {
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/voicemail/pwd/' + userId)
			.then(res => {
				alert(res.password);
			})
			.catch(err => {
				console.log('Error in fetching Password', err);
				Notify.notify('Error in fetching Password');
			});
		
	}
	addCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToAdd <= 0) {
			Notify.notify('Recharge Amount cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToAdd,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/credits/add', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed.');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToAdd: 0
				})
				this.getUsers();
				//window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}

	removeCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToRemove <= 0) {
			Notify.notify('Recharge amount cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToRemove,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/credits/remove', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToRemove: 0
				})
				this.getUsers();
				//window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}

	resetPassword = (event) => {
		event.preventDefault();
		if (this.state.confirmPassword !== this.state.password) {
			Notify.notify('Password & Confim Password don\'t match');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			password: this.state.password,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/pwd/reset', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Reset Password Failed');
				} else {
					Notify.notify(res.message);
				}
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Reset Password', err)
				Notify.notify('Error in Reset Password');
			});
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showAddCreditModal: false,
			showRemoveCreditModal: false,
			showPwdModal: false,
		});
	}

	showAddCreditModal = (userId) => {
		this.setState({
			showAddCreditModal: true,
			selectedUserId: userId
		});
	}
	showRemoveCreditModal = (userId) => {
		this.setState({
			showRemoveCreditModal: true,
			selectedUserId: userId
		});
	}

	showPwdModal = (userId) => {
		this.setState({
			showPwdModal: true,
			selectedUserId: userId
		});
	}

	allocateApiCli = (status, userId) => {
		this.setState({
			confirmAllocateApiCliUserId: status,
			selectedAllocateApiCliUserId: userId,
			manualAllocation : false,
			bulkAllocation: false
		});

		const apiPath = this.props.app.apiPath;
        
		if(status){
		Fetcher.get(apiPath + '/api/voicemail/api/cli/allocated/' + userId)
			.then(res => {
				this.setState({
					allocatedCliList: res
				})
			})
			.catch(err => {
				console.log('Error in fetching Users List', err);
			});
		}

	}


	removeManualBulkCli = (allocateApiCliIdStr) => {

		const allocateApiCliIds = allocateApiCliIdStr.split(',');
		const cliList = this.state.cliList.filter(val => {
			return !Boolean( allocateApiCliIds.includes(val.cli) );
		});

		this.setState({ cliList: cliList })

	}


	submitAllocateCli = (event) => {
		event.preventDefault();
		console.log("Manual cli : "+this.state.manualAllocation);
		console.log("Buld cli : "+this.state.bulkAllocation);
		console.log("CliIds : "+this.state.allocateApiCliId);
		console.log("Cli Id : "+this.state.cliId);
		console.log("Range : "+this.state.cliRange);

		if (this.state.cliRange !== '') {
			if(Number(this.state.cliRange <=0)){
			Notify.notify('Cli Range can not be negative or 0');
			return;
			}
		}
		let data = {};
		if(this.state.manualAllocation){
		    data = {
			      userId: this.state.selectedAllocateApiCliUserId.toString(),
			      allocationType: "manual",
			      cli: this.state.allocateApiCliId
		   }
	    }else if(this.state.bulkAllocation){
			data = {
			      userId: this.state.selectedAllocateApiCliUserId.toString(),
			      allocationType: "bulk",
			      cli: this.state.cliId,
				  cliRange: this.state.cliRange
		   }
		}
		console.log(JSON.stringify(data));

		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/api/cli/allocate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					this.state.confirmAllocateApiCliUserId = false
					Notify.notify('Allocation Failed');
				} else {
					this.state.confirmAllocateApiCliUserId = false
					Notify.notify(res.message);
					if(this.state.manualAllocation) this.removeManualBulkCli(this.state.allocateApiCliId);
					else if(this.state.bulkAllocation) this.removeManualBulkCli(this.state.cliId);
				}
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Allocate Cli', err)
				Notify.notify('Error in Allocate Cli');
			});

	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
		if(name === "manualAllocation"){
			this.state.bulkAllocation = false
		}
		if(name === "bulkAllocation"){
			this.state.manualAllocation = false
		}
	}

	removeAllocateCli = (event) => {
		event.preventDefault();
		console.log("Here Remove");
		console.log("Selected Clis : "+this.state.allocatedClisIds);
		const data = {
			userId: this.state.selectedAllocateApiCliUserId.toString(),
			cliIds: this.state.allocatedClisIds
		}
		
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/api/remove/cli', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					this.state.confirmAllocateApiCliUserId = false;
					Notify.notify('Remove Cli Failed');
				} else {
					this.state.confirmAllocateApiCliUserId = false;
					Notify.notify(res.message);
				}
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Removing Cli', err)
				Notify.notify('Error in Removing Cli');
			})
			.finally(() => {
				this.setState({ confirmRemoveAllocatedCli: false })
			});

	}


	handleSelect = function (selectedItems) {
		const cliSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			cliSelected.push(selectedItems[i].value);
		}
		this.setState({
			allocateApiCliId: cliSelected.toString()
		});
		console.log("Cli Ids : "+this.state.allocateApiCliId);
	}


	handleCliSelect = function (selectedItems) {
		const cliSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			cliSelected.push(selectedItems[i].value);
		}
		this.setState({
			allocatedClisIds: cliSelected.toString()
		});
		console.log("Cli Ids : "+this.state.allocatedClisIds);
	}


	getCli = () => {
		const apiPath = this.props.app.apiPath;
        const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/voicemail/cli/api/'+userId)
			.then(res => {
				this.setState({
					cliList: res
				})
			})
			.catch(err => { console.log('Error in fetching CLI List', err) });
	}

	render() {

		const userList = this.state.userList;
		var columns;
		if (this.props.app.role === 'ROLE_SADMIN') {
			columns = [
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
					hidden: true
				},
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'name',
					text: 'Name',
					sort: true,
					searchable: true
				}, {
					dataField: 'emailId',
					text: 'Email Id',
					sort: true,
					searchable: true
				}, {
					dataField: 'number',
					text: 'Mobile',
					sort: true,
					searchable: false
				},{
					dataField: 'allocateCLI',
					text: 'Allocate CLI',
					sort: false,
					searchable: false,
					csvExport: false
				},{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false,
					csvExport: false,
					hidden: true
				},];
		}
		else {
			columns = [
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
					hidden: true
				},
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'name',
					text: 'Name',
					sort: true,
					searchable: true
				}, {
					dataField: 'emailId',
					text: 'Email Id',
					sort: true,
					searchable: true
				}, {
					dataField: 'number',
					text: 'Mobile',
					sort: true,
					searchable: false
				}, 
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false,
					csvExport: false,
					hidden: true
				}
			];
		}
		const users = Array.isArray(userList) ? userList.map((user, index) => {
			if (this.props.app.role === 'ROLE_SADMIN') {
				let action = [];
				action.push(<Dropdown key={`${index}-dropdown`}>
					<Dropdown.Toggle className="btn-sm" variant="primary">Take Action</Dropdown.Toggle>
						<Dropdown.Menu>
					  		<Dropdown.Item key={`${index}-edit-user`} href={`${this.props.app.urlPrefix}/update-user/${user.userId}`}>Edit User</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#">Add Recharge</Dropdown.Item>
							<Dropdown.Item key={`${index}-remove-credit`} onClick={(e) => { this.showRemoveCreditModal(user.userId) }} href="#">Remove Recharge</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-reset-pwd`} onClick={(e) => { this.showPwdModal(user.userId) }} href="#">Reset Password</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-delete-user`} onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#">Delete User</Dropdown.Item>
							  <Dropdown.Item key={`${index}-pwd`} onClick={(e) => { this.showPassword(user.userId) }} href="#">Show Password</Dropdown.Item>
						</Dropdown.Menu>
				  	</Dropdown>);
				/*let action = [<Link id="edit-btn" data-tooltip-content="Edit User Profile" key={`${index}-edit-user`} className="edit-user" to={`${this.props.app.urlPrefix}/update-user/${user.userId}`}><i className="fa fa-edit"></i></Link>];
				action.push(<a id="add-btn" data-tooltip-content="Add Credits" key={`${index}-add-credit`} className="add-credit" onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#"><i className="fa fa-plus"></i></a>)
				action.push(<a data-tooltip-content="Reset Password" key={`${index}-reset-pwd`} className="reset-pwd" onClick={(e) => { this.showPwdModal(user.userId) }} href="#"><i className="fa fa-refresh"></i></a>)
				action.push(<a data-tooltip-content="Delete User" key={`${index}-delete-user`} className="delete-user" onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#"><i className="fa fa-trash"></i></a>)*/
				let action2 = [<a className="btn btn-sm btn-success" key={`${index}-allocate-api-cli`}  onClick={(e) => { this.allocateApiCli(true, user.userId) }} href="#">Allocate</a>];

				return {
					userId: user.userId,
					username: user.username,
					name: user.name,
					emailId: user.emailid,
					number: user.number,
					company: user.company,
					userType: user.userType,
					credits: user.credits,
					planName: user.planName,
					parentUser: user.parentUser,
					expiryDate: user.expiryDate,
					//action: action,
					allocateCLI: action2
				};
			}
			else {
				let action = [];
				action.push(<Dropdown>
					<Dropdown.Toggle className='btn-sm'>Take Action</Dropdown.Toggle>
						<Dropdown.Menu>
						<Dropdown.Item key={`${index}-edit-user`} href={`${this.props.app.urlPrefix}/update-user/${user.userId}`}>Edit User</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#">Add Recharge</Dropdown.Item>
							<Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showRemoveCreditModal(user.userId) }} href="#">Remove Recharge</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-reset-pwd`} onClick={(e) => { this.showPwdModal(user.userId) }} href="#">Reset Password</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-delete-user`} onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#">Delete User</Dropdown.Item>
						</Dropdown.Menu>
				  	</Dropdown>);
				/*let action = [<Link data-tip="Edit User Profile" key={`${index}-edit-user`} className="edit-user" to={`${this.props.app.urlPrefix}/update-user/${user.userId}`}><i className="fa fa-edit"></i></Link>];
				action.push(<a data-tip="Add Credits" key={`${index}-add-credit`} className="add-credit" onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#"><i className="fa fa-plus"></i></a>)
				action.push(<a data-tip="Reset Password" key={`${index}-reset-pwd`} className="reset-pwd" onClick={(e) => { this.showPwdModal(user.userId) }} href="#"><i className="fa fa-refresh"></i></a>)
				action.push(<a data-tip="Delete User" key={`${index}-delete-user`} className="delete-user" onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#"><i className="fa fa-trash"></i></a>)*/
				return {
					userId: user.userId,
					username: user.username,
					name: user.name,
					emailId: user.emailid,
					number: user.number,
					company: user.company,
					userType: user.userType,
					credits: user.credits,
					planName: user.planName,
					parentUser: user.parentUser,
					expiryDate: user.expiryDate,
					//action: action
				};
			}
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'userId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: users.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		//const cliList = cloneDeep(this.state.cliList);
		let cliDropdown = '';
		if (!isEmpty(this.state.cliList) && (!('error' in this.state.cliList))) {
			cliDropdown = Array.isArray(this.state.cliList) ? this.state.cliList.map((obj, index) => {
				return <option value={obj.cli} key={`cliId${index}`} >{obj.cli}</option>
			}) : []

		} else {
			cliDropdown = <option value="" >No Cli found</option>
		}


		const allocatedcliList = cloneDeep(this.state.allocatedCliList);
		let allocatedCliDropdown = '';
		if(allocatedcliList.length >0){
			this.state.allocatedCliListFlag = true;
		}
		if (!isEmpty(allocatedcliList) && (!('error' in allocatedcliList))) {
			allocatedCliDropdown = Array.isArray(allocatedcliList) ? allocatedcliList.map((obj, index) => {
				return <option value={obj.cliId} key={`cliId${index}`} >{obj.cli}</option>
			}) : []

		} else {
			allocatedCliDropdown = <option value="" >No Allocated Cli found</option>
		}

		return (
			<Card>
				<Card.Header>Users List</Card.Header>
				{/*<Card.Header>Users List<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span></Card.Header>*/}

				<Card.Body>
					<div className="user-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="userId"
										data={users}
										columns={columns}
										search
										bootstrap4
										exportCSV={ {
											fileName: 'users.csv'
										  } }
									>
										{
											props => (
												<div>
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													{/* <Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/create-user`}>Add User</Link> */}
													<hr />
													<BootstrapTable 
													noDataIndication="No records found"
													loading={this.state.loading}
													overlay={overlayFactory({ spinner: true, styles: { background: 'rgba(0, 0, 0, 0.5)' } })}
													defaultSorted={defaultSorted} hover bordered={true} wrapperClasses="table-responsive" classes="userlist-table custom-data-table"  {...props.baseProps}  {...paginationTableProps} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.showAddCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Add Recharge Amount
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.addCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group>
											<Form.Control required name="creditsToAdd" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToAdd} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Add Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.showRemoveCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Remove Recharge Amount
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.removeCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group>
											<Form.Control required name="creditsToRemove" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToRemove} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Remove Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.showPwdModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Reset Password
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.resetPassword}>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Reset Password</Button>
							</Form>
						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete User
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this user?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deleteUser} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

					<Modal size="lg" show={this.state.confirmAllocateApiCliUserId} onHide={() => this.allocateApiCli(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Allocate Api CLI
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.submitAllocateCli}>
								<Row>
									<Col >
									<Form.Group className="form-group" >
											<Form.Check checked={this.state.manualAllocation} onChange={( e ) => this.toggleChange( e, 'manualAllocation' )} inline name="manualAllocation" label="Manual Allocation" id="manualAllocation" type="checkbox" />
									</Form.Group>
                                    </Col>
									<Col>
									<Form.Group className="form-group" >
											<Form.Check checked={this.state.bulkAllocation} onChange={( e ) => this.toggleChange( e, 'bulkAllocation' )} inline name="bulkAllocation" label="Bulk Allocation" id="bulkAllocation" type="checkbox" />
									</Form.Group>
									</Col>
								</Row>
								<Row>
								{(this.state.manualAllocation) && 
								    <Col>
									<Form.Group className="form-group channels control-label">
											<Form.Label>Select Multiple Cli</Form.Label>
											<Form.Select className="dd" name="allocateApiCliId" onChange={(e) => { this.handleSelect(e.target.selectedOptions) }} multiple as="select">
												{cliDropdown}
											</Form.Select>
										</Form.Group>
									</Col> 
								}
								</Row>
								<Row>
								{(this.state.bulkAllocation) && 
								    <Col sm={6}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="cliId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Cli</option>
												{cliDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								}
								</Row>
								<Row>
								{(this.state.bulkAllocation) && 
								    
									<Col sm={6}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="cliRange" type="number" placeholder="Range" onChange={e => this.setvalue(e)} value={this.state.cliRange} />
										</Form.Group>
									</Col>
								}
								</Row>
								<Modal.Footer>
							          <Button onClick={() => this.allocateApiCli(false, '')} variant="secondary">CANCEL</Button>
							          <Button variant="primary" type="submit">Submit</Button>
						        </Modal.Footer>	
							</Form>

                         <Form method="post" onSubmit={this.removeAllocateCli}>
                            <Row>
								{(true) && 
								    <Col>
									<Form.Group className="form-group channels control-label">
											<Form.Label>Allocated Clis</Form.Label>
											<Form.Select className="dd" name="allocatedClisIds" onChange={(e) => { this.handleCliSelect(e.target.selectedOptions) }} multiple as="select">
												{allocatedCliDropdown}
											</Form.Select>
										</Form.Group>
									</Col> 
								}
							</Row>
							{ (this.state.allocatedCliListFlag) && <Modal.Footer>
							 <Button onClick={() => this.allocateApiCli(false, '')} variant="secondary">CANCEL</Button>
							 <Button variant="danger" type="button" onClick={() => this.confirmAllocatedCliRemove(true)}>Remove</Button>
							</Modal.Footer>}
						 </Form>

						</Modal.Body>
					</Modal>


					<Modal size="md" show={this.state.confirmRemoveAllocatedCli} onHide={() => this.confirmAllocatedCliRemove(false, '','')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Remove allocated CLI(s)
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to Remove the allocated CLI(s)?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmAllocatedCliRemove(false)} variant="secondary">CANCEL</Button>
							<Button onClick={this.removeAllocateCli} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

				</Card.Body>
			</Card>

		);
	}
}

export default UserManagement;