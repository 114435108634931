import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Tabs, Tab } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const cliLegs = ['thinq', 'Piratel','382'];

class AddVoiceMailCampaign extends Component {
	constructor(props) {
		super(props);

		this.baseFileInput = React.createRef();
		let sitePath = '';
		let filePath = '';

		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

		this.state = {
			campaignName: '',
			baseName: '',
			
			templateId: '0',
			baseFileType: 'contactList',
			sendLater: false,
			sendDate: new Date(),
			baseFileInput: this.baseFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			contactList: '',
			oldBaseId: '',
			promptId: '',
			welcomeId: '',
			allocatedCliList: [],
			senderId: '',
			baseUploads: [],
			promptUploads: [],
			senderIdList: [],
			redirectToSummary: false,
			campaignCreated: false,
			prompt: true,
			welcomePrompt: false,
			menuPrompt: true,
			noinputPrompt: false,
			wronginputPrompt: false,
			thanksPrompt: false,
			sendLink: false,
			channels: '',
			channelsList: [],
			trunkLeg1: '',
			cliLeg1: '',
			trunkLeg2: '',
			cliLeg2: ''
		}

	}

	componentDidMount() {
		const userId = this.state.userId;
		const apiPath = this.state.apiPath;

		// Get BaseUpload Files List
		Fetcher.get(apiPath + '/api/voicemail/base/' + userId)
			.then(res => {
				this.setState({
					baseUploads: res
				})
			})
			.catch(err => { console.log('Error in fetching BaseUpload Files', err) });

		// Get PromptUpload Files List
		Fetcher.get(apiPath + '/api/voicemail/prompts/' + userId)
			.then(res => {
				this.setState({
					promptUploads: res
				})
			})
			.catch(err => { console.log('Error in fetching PromptUpload Files', err) });

		// Get Sender Id List
		Fetcher.get(apiPath + '/api/voicemail/cli/user/' + userId)
			.then(res => {
				this.setState({
					senderIdList: res
				})
			})
			.catch(err => { console.log('Error in fetching Sender Id\'s', err) });

		// Get Channels
		Fetcher.get(apiPath + '/api/voicemail/channels/' + userId)
			.then(res => {
				var slots = res.channels / 20;
				var channelList = [];
				for (let i = 1; i <= slots; i++) {
					const item = {
						slot: i * 20
					};
					channelList.push(item);
				}
				this.setState({
					channelsList: channelList
				})
			})
			.catch(err => { console.log('Error in fetching Channels', err) });

		this.getAllocatedCli()
		
	}


	getAllocatedCli = () => {
		const apiPath = this.props.app.apiPath;
        const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/voicemail/api/cli/allocated/' + userId)
			.then(res => {
				this.setState({
					allocatedCliList: res
				})
			})
			.catch(err => {
				console.log('Error in fetching Users List', err);
			});
	}

	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}

	handleSelect = function (selectedItems) {
		const dtmfSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			dtmfSelected.push(selectedItems[i].value);
		}
		this.setState({
			dtmf: dtmfSelected.toString()
		});
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	setTemplate = (e) => {
		this.setState({
			//campaignName: '',
			baseName: '',
			baseFileType: 'contactList',
			sendLater: false,
			sendDate: new Date(),
			baseFileInput: this.baseFileInput,
			contactList: '',
			oldBaseId: '',
			promptId: '',
			welcomeId: '',
			senderId: '',
			redirectToSummary: false,
			campaignCreated: false,
			prompt: true,
			sendLink: false,
			templateId: 0
		});
	}
	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}

	// Add Campaign
	handleAddCampaignSubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		const date = formData.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " "
		sendDateTime += this.addZero(date.getHours());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getMinutes());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getSeconds());
		var baseId = '';
		await this.uploadBaseAndPromptFiles(formData)
			.then(([resp]) => {
				if(formData.baseFileType === 'newBaseFile')
				{
					if(resp.baseId === undefined)
					{
						Notify.notify(resp.message);
						return;
					}
					else
					{
						baseId = resp.baseId;
					}
				}
				else if (formData.baseFileType === 'oldBaseFile') {
					baseId = resp;
				}
				else if(formData.baseFileType === 'contactList')
				{
					baseId = resp.baseId;
				}
				
				const data = {
					"userId": formData.userId,
					"campaignName": formData.campaignName,
					"templateId": formData.templateId,
					"baseId": baseId,
					"welcomePId": formData.welcomeId,
					"scheduleTime": sendDateTime,
					"channels": formData.channels,
					"trunkLeg1": formData.trunkLeg1,
					"cliLeg1": formData.cliLeg1,
					"trunkLeg2": formData.trunkLeg2,
					"cliLeg2": formData.cliLeg2
				}
				this.addNewCampiagn(data);

			})

	}

	uploadBaseAndPromptFiles = async (formData) => {
		return new Promise(async (resolve, reject) => {

			var baseId = await this.maybeUploadBasefile(formData);
			resolve([baseId]);
			//reject();
		})
		//return Promise.all( [ this.maybeUploadBasefile( formData ), this.maybeUploadWelcomefile( formData ), this.maybeUploadMenufile( formData ), this.maybeUploadNoinputfile( formData ), this.maybeUploadWronginputfile( formData ), this.maybeUploadThanksfile( formData ) ] )
	}

	maybeUploadBasefile = async (formData) => {
		if (formData.baseFileType === 'oldBaseFile') {
			return formData.oldBaseId
		}
		else if (formData.baseFileType === 'contactList') {
			const fd = new FormData();
			fd.append('userId', formData.userId);
			fd.append('contactList', formData.contactList);
			//console.log(fd);
			return Fetcher.post(formData.apiPath + '/api/voicemail/contactlist', { method: 'POST', body: fd })
				.then(res => {
					return res;
				})
				.catch(err => { console.log('Error in uploading BaseUpload Files to Server', err) });
		}
		else {
			const fd = new FormData();
			fd.append('baseFile', formData.baseFileInput.current.files[0]);
			fd.append('baseName', formData.baseName);
			fd.append('userId', formData.userId);
			fd.append('contactList', '');
			return Fetcher.post(formData.apiPath + '/api/voicemail/baseupload', { method: 'POST', body: fd })
				.then(res => {
					return res;
				})
				.catch(err => { console.log('Error in uploading BaseUpload Files to Server', err) });
		}
	}

	addNewCampiagn = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/voicemail/campaign/compose', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let campaignCreated = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					campaignCreated = true;
					Notify.notify(res.message);
				}
				this.setState({
					redirectToSummary: campaignCreated
				})
			})
			.catch(err => {
				console.log('Error in Add Campaign', err);
				Notify.notify('Error in Add Campaign');
			});
	}


	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		} else {
			allowedTypes = ['audio/wav'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null;
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			event.target.value = null;
		}
		return true;
	}

	render() {
		// Redirect to Summary if Campaign Added successfully.
		if (this.state.redirectToSummary === true) {
			return <Redirect to='/voicemail-campaign-summary' />
		}

		//console.log( 'Add Campaign State', this.state );
		const baseUploads = cloneDeep(this.state.baseUploads);
		const promptUploads = cloneDeep(this.state.promptUploads);
		const senderIdList = cloneDeep(this.state.senderIdList);
		const channelList = cloneDeep(this.state.channelsList);
		const sendLater = this.state.sendLater;
		let sendLaterDatepicker = '';
		let submitButtonlabel = 'Send Now';

		if (sendLater) {
			const filterTime = (date) => {
				const isPastTime = new Date().getTime() > date.getTime();
				return !isPastTime;
				};
			sendLaterDatepicker = <Row>
				<Col sm={10}>
					<Form.Group controlId="send-date">
						<Form.Label>Enter Date</Form.Label>
						<DatePicker
							className="form-control"
							showTimeSelect
							timeIntervals={15}
							minDate={new Date()}
							selected={this.state.sendDate}
							timeCaption="Time"
							dateFormat="yyyy-MM-dd hh:mm:ss"
							onChange={this.sendLaterDateChange}
							filterTime={filterTime}
						/>
					</Form.Group>
				</Col>
			</Row>

			submitButtonlabel = 'Compose Campaign';
		}

		let senderIdDropdown = '';
		if (!isEmpty(senderIdList) && (!('error' in senderIdList))) {
			senderIdDropdown = senderIdList.map((obj, index) => {
				return <option value={obj.cli} key={`senderid${index}`} >{obj.cli}</option>
			})

		} else {
			senderIdDropdown = <option value="" >No ID found</option>
		}

		let channelDropdown = '';
		if (!isEmpty(channelList) && (!('error' in channelList))) {
			channelDropdown = channelList.map((obj, index) => {
				return <option value={obj.slot} key={`channel${index}`} >{obj.slot}</option>
			})

		} else {
			channelDropdown = <option value="" >No Channel found</option>
		}

		let baseUploadDropdown = '';

		if (!isEmpty(baseUploads) && (!('error' in baseUploads))) {
			baseUploadDropdown = baseUploads.map((fileObj, index) => {
				return <option value={fileObj.baseId} key={`basefile${index}`} >{fileObj.baseName}</option>
			})

		} else {
			baseUploadDropdown = <option value="" >No Files</option>
		}

		let welcomeDropdown = '';

		if (!isEmpty(promptUploads) && (!('error' in promptUploads))) {

			welcomeDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'welcome') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					welcomeDropdown = <option value="" >No Files</option>
				}
			})
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddCampaignSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Compose Voice Mail Campaign</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group controlId="campaign-name">
											<Form.Control required name="campaignName" onChange={e => this.setvalue(e)} value={this.state.campaignName} type="text" placeholder="Campaign Name" />
										</Form.Group>
									</Col>
								</Row>

								<Row>
									
									<Form.Label>Select Trunk and CLI</Form.Label>
									
									<Col sm={3}>
										<Form.Select required name="trunkLeg1" onChange={e => this.setvalue(e)} aria-label="Select Trunk Leg1">
											<option value="">Select Trunk Leg1</option>
											{
												cliLegs.map( val =>
													<option key={`leg1-${val}`} value={val}>{val}</option>
												)
											}
										</Form.Select>
									</Col>

									<Col sm={3}>
										<Form.Select required name="cliLeg1" onChange={e => this.setvalue(e)} aria-label="Select CLI Leg1">
											<option value="">Select CLI Leg1</option>
											{
												this.state.allocatedCliList.map(val => 
													<option key={`leg1-${val.cli}`} value={val.cli}>{val.cli}</option>
												)
											}
										</Form.Select>
									</Col>

									<Col sm={3}>
										<Form.Select required name="trunkLeg2" onChange={e => this.setvalue(e)} aria-label="Select Trunk Leg1">
											<option value="">Select Trunk Leg2</option>
											{
												cliLegs.map( val =>
													<option key={`leg2-${val}`} value={val}>{val}</option>
												)
											}
										</Form.Select>
									</Col>

									<Col sm={3}>
										<Form.Select required name="cliLeg2" onChange={e => this.setvalue(e)} aria-label="Select CLI Leg1">
											<option value="">Select CLI Leg2</option>
											{
												this.state.allocatedCliList.map(val => 
													<option key={`leg2-${val.cli}`} value={val.cli}>{val.cli}</option>
												)
											}
										</Form.Select>
									</Col>

								</Row>
								
								<Row>
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group>
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>
								
								<Row>
									<Col sm={4}>
										<Form.Label>Upload Wav File</Form.Label>
										<Form.Select as="select" required name="welcomeId" onChange={e => this.setvalue(e)}>
											<option value="">Select File</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
								</Row>

								<Row>
									<Col sm={4}>
										<Form.Select as="select" required name="channels" onChange={e => this.setvalue(e)}>
											<option value="">Select Channels</option>
											{channelDropdown}
										</Form.Select>
									</Col>
								</Row>
								
								<Row>
									<Col sm={6}>
										<Form.Group controlId="Send-later">
											<Form.Check checked={this.state.sendLater} onChange={(e) => this.toggleChange(e, 'sendLater')} custom="true" inline name="send-later" label="Schedule Call" id="send-later" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>
								{sendLaterDatepicker}
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default AddVoiceMailCampaign;