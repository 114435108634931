import React, { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Fetcher } from '../../Helpers/fetcher.js';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import './index.css';
class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userCampaignList: [],
			todayCampaignList: [],
			campaignList: [],
			dashboardData: []
		}
	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/voicemail/dashboard/' + userId)
			.then(res => {
				this.setState({
					dashboardData: res.dbHeader
				})
			})
			.catch(err => { console.log('Error in fetching Dashboard Data', err) });
		this.getUsers();
	}

	getUsers = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/voicemail/dashboard/' + userId)
			.then(res => {
				this.setState({
					userCampaignList: res.dbUserWiseCampaigns,
					todayCampaignList: res.dbTodayCampaigns,
					showModal: false,
					showAddCreditModal: false,
					showPwdModal: false,
					confirmDelete: false,
				})
			})
			.catch(err => { console.log('Error in fetching Dashboard Data', err) });
	}

	campaignSummary = (userId) => {
		Fetcher.get(this.props.app.apiPath + '/api/voicemail/campaign/sadmin/' + userId)
			.then(res => {
				this.setState({
					campaignList: res,
					confirmStop: false,
					confirmPause: false,
					confirmResume: false,
					confirmRetry: false
				})
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });
	}

	render() {
		const todayCampaignList = this.state.todayCampaignList;
		var todayColumns;
		if (this.props.app.role === 'ROLE_SADMIN') {
			todayColumns = [
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'campaignName',
					text: 'Campaign Name',
					sort: true,
					searchable: true
				},
				{
					dataField: 'campaignStatus',
					text: 'Status',
					sort: true,
					searchable: true
				},
				{
					dataField: 'scheduleDate',
					text: 'Date',
					sort: true,
					searchable: true
				},];
		}
		const todayCampaigns = Array.isArray(todayCampaignList) ? todayCampaignList.map((tcampaigns, index) => {
			let status = '';

			switch (tcampaigns.campaignStatus) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
						break;
				default:
					status = '';
					break;
			}
				return {
					username: tcampaigns.username,
					campaignName: tcampaigns.campaignName,
					campaignStatus: status,
					scheduleDate: tcampaigns.scheduleDate
				};
			}) : []

		const paginationOption1 = {
			custom: true,
			totalSize: todayCampaigns.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		const userCampaignList = this.state.userCampaignList;
		var columns;
		if (this.props.app.role === 'ROLE_SADMIN') {
			columns = [
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'totalCampaigns',
					text: 'Running Campaigns',
					sort: true,
					searchable: true
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: true,
					searchable: true
				},];
		}
		else if (this.props.app.role === 'ROLE_ADMIN'){
			columns = [
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'totalCampaigns',
					text: 'Running Campaigns',
					sort: true,
					searchable: true
				},];
		}
		const users = Array.isArray(userCampaignList) ? userCampaignList.map((user, index) => {
			if (this.props.app.role === 'ROLE_SADMIN') {
				let action=[];
				action.push( <a data-tip="" key={`${user.userId}`} className="btn btn-sm btn-primary" onClick={( e ) => { this.campaignSummary(user.userId) }} href="#">View Campaigns</a>);
				return {
					username: user.username,
					totalCampaigns: user.totalCampaigns,
					action:action
				};
			}
			else {
				return {
					username: user.username,
					totalCampaigns: user.totalCampaigns
				};
			}
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const paginationOption = {
			custom: true,
			totalSize: users.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};
		const defaultSorted1 = [{
			dataField: 'scheduleDate',
			order: 'desc'
		}];

		const campaigns = this.state.campaignList;
		const ccolumns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true
			},
			{
				dataField: 'campaignId',
				text: 'Campaign ID',
				sort: false,
				hidden: false
			},
			{
				dataField: 'name',
				text: 'Campaign Name',
				sort: true,
				searchable: true
			}, {
				dataField: 'campaignstatus',
				text: 'Campaign Status',
				sort: true,
				searchable: true
			}, {
				dataField: 'templateId',
				text: 'Campaign Type',
				sort: true,
				searchable: true
			},{
				dataField: 'scheduleTime',
				text: 'Start Time',
				sort: true,
				searchable: false
			},{
				dataField: 'endTime',
				text: 'End Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'numbersUploaded',
				text: 'Total Numbers',
				sort: false,
				searchable: false
			}, {
				dataField: 'numbersProcessed',
				text: 'Calls Dialed',
				sort: false,
				searchable: false
			}, {
				dataField: 'callsSubmitted',
				text: 'Pending Calls',
				sort: false,
				searchable: false
			},{
				dataField: 'callsConnected',
				text: 'Connected Calls',
				sort: false,
				searchable: false
			},{
				dataField: 'pulses',
				text: 'Total Pulses',
				sort: true,
				searchable: false
			},{
				dataField: 'dndCount',
				text: 'DnD Count',
				sort: true,
				searchable: false
			},{
				dataField: 'dtmfCount',
				text: 'Dtmf Count',
				sort: true,
				searchable: false
			},{
				dataField: 'smsCount',
				text: 'SMS Count',
				sort: true,
				searchable: false
			},{
				dataField: 'retryCount',
				text: 'Retry Count',
				sort: true,
				searchable: false
			},{
				dataField: 'retries',
				text: 'Is Retry',
				sort: true,
				searchable: false,
				hidden: true
			},{
				dataField: 'retries',
				text: 'No. Of retries',
				sort: true,
				searchable: false
			},];

		const products = Array.isArray(campaigns) ? campaigns.map((campaign, index) => {
			let template = '';

			switch (campaign.templateId) {
				case 0:
					template = 'Simple IVR';
					break;
				case 1:
					template = 'DTMF';
					break;
				case 2:
					template = 'Call Patch';
					break;
				default:
					template = '';
					break;
			}
			let status = '';

			switch (campaign.campaignStatus) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
						break;
				default:
					status = '';
					break;
			}
			return {
				id: index + 1,
				campaignId: campaign.campaignId,
				name: campaign.campaignName,
				campaignstatus: status,
				templateId: template,
				scheduleTime: campaign.scheduleTime,
				endTime: campaign.endTime,
				numbersUploaded: campaign.numbersUploaded,
				numbersProcessed: campaign.numbersProcessed,
				callsSubmitted: campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
				callsConnected: campaign.callsConnected,
				pulses: campaign.pulses,
				dndCount: campaign.dndCount,
				dtmfCount: campaign.dtmfCount,
				smsCount: campaign.smsCount,
				retryCount: campaign.retryCount,
				retries: (campaign.retries > 0)?'Yes':'No',
				retries: campaign.retries,
			};
		}) : []
		const defaultSorted = [{
			dataField: 'scheduletime',
			order: 'desc'
		}];
		return (
			<div className="animated fadeIn">
				<Card>
					<Card.Header>Voice Dashboard</Card.Header>
					<Card.Body>
						{this.props.app.role === 'ROLE_USER' && <Row>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total Voice Campaigns</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.totalVoiceCampaigns}</div>

									</Card.Body>
								</Card>
							</Col>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-primary dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total Calls</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.totalCalls}</div>

									</Card.Body>
								</Card>
							</Col>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-success dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Connected Calls</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.connectedCalls}</div>

									</Card.Body>
								</Card>
							</Col>
							
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Balance Available(Rs)</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.voiceCreditsAvailable}</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>}
						{(this.props.app.role === 'ROLE_SADMIN') && <Row>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total Resellers</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.resellers}</div>
									</Card.Body>
								</Card>
							</Col>

							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-success dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total End Users</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.endUsers}</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>}
						{(this.props.app.role === 'ROLE_ADMIN') && <Row>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total Resellers</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.resellers}</div>

									</Card.Body>
								</Card>
							</Col>
							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-info dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Total End Users</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.endUsers}</div>

									</Card.Body>
								</Card>
							</Col>

							<Col xs="12" sm="6" lg="3">
								<Card className="text-white bg-success dashboard-card">
									<Card.Body className="pb-0">
										<div className="dashboard-card-heading">Credits Available</div>
										<div className="text-value dashboard-card-data">{this.state.dashboardData.voiceCreditsAvailable}</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>}
						{(this.props.app.role === 'ROLE_SADMIN') && <Row>
						<Col sm={12}>
							<Card>
								{<Card.Header>Today's Campaigns</Card.Header>}
								<Card.Body>
								<div className="user-list-table-cont">
								{<PaginationProvider pagination={paginationFactory(paginationOption1)}>
									{
										({
											paginationProps,
											paginationTableProps
										}) => (
											<ToolkitProvider
												keyField="scheduleDate"
												data={todayCampaigns}
												columns={todayColumns}
												search
												bootstrap4
											>
												{
													props => (
														<div className="">
															Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
															<span className="search-cont"><SearchBar {...props.searchProps} /></span>
															<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
															<hr />
															<BootstrapTable bordered={true} defaultSorted={defaultSorted1} striped={true} condensed wrapperClasses="table-responsive" classes="userCampaignList-table"  {...props.baseProps}  {...paginationTableProps} />
															<PaginationListStandalone {...paginationProps} />
														</div>
													)
												}
											</ToolkitProvider>
										)
									}
								</PaginationProvider>}
							</div>
								</Card.Body>
							</Card>
							</Col>
						</Row>}
						{(this.props.app.role === 'ROLE_SADMIN') && <Row>
							<Col sm={12}>
							<Card>
							{<Card.Header>Running Campaigns</Card.Header>}
							<Card.Body>

							<div className="user-list-table-cont">
								{<PaginationProvider pagination={paginationFactory(paginationOption)}>
									{
										({
											paginationProps,
											paginationTableProps
										}) => (
											<ToolkitProvider
												keyField="username"
												data={users}
												columns={columns}
												search
												bootstrap4
											>
												{
													props => (
														<div className="">
															Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
															<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
															<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
															{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
															<hr />
															<BootstrapTable bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="userCampaignList-table"  {...props.baseProps}  {...paginationTableProps} />
															<PaginationListStandalone {...paginationProps} />

														</div>
													)
												}
											</ToolkitProvider>
										)
									}
								</PaginationProvider>}
							</div>
							<div className="campaign-list-table-cont">
						{/* <h3>Campaign Summary</h3> */}
						{/*{!isEmpty( this.state.campaignlist ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (<ToolkitProvider 
										keyField="id" 
										data={products} 
										columns={ccolumns}  
										bootstrap4
										>
									{props => (<div className="">
										<hr />
										<BootstrapTable bordered={true} hover defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="w-auto campaignlist-table" {...props.baseProps} {...paginationTableProps} />
									</div>)}
								</ToolkitProvider>)
							}
						</PaginationProvider>}
					</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>}
		</Card.Body>
	</Card>
</div>
		);
	}

}

export default Dashboard;