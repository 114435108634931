import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { Redirect } from "react-router-dom";


// import "./index.css";
class UpdateTrunk extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialerName: '',
			dialerId: '',
			trunkName: '',
			cps: '',
			channels: '',
			pilotNumber: '',
			dialerList: [],
			redirectToTrunkManagement: false
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const trunkId = this.props.selectedTrunkId;
		Fetcher.get(apiPath + '/api/voicemail/trunk/detail/' + trunkId)
			.then(res => {
				this.setState({
					trunkName: res.trunkName,
					pilotNumber: res.pilotNumber,
					dialerName: res.dialerName,
					cps: res.cps,
					channels: res.channels,
					trunkId: res.trunkId
				})
			})
			.catch(err => { console.log('Error in fetching Trunk data', err) });
		
			const apiUrl = apiPath + '/api/voicemail/dialers/';
		
			Fetcher.get(apiUrl)
				.then(res => {
					this.setState({
						dialerList: res
					})
				})
				.catch(err => { console.log('Error in fetching Sender Id\'s', err) });
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			redirectToTrunkManagement: this.state.redirectToTrunkManagement
		});
	}


	updateTrunk = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);

		const data = {
			trunkId: this.props.selectedTrunkId,
			trunkName: state.trunkName,
			dialerId: state.dialerId,
			cps: state.cps,
			channels: state.channels,
			pilotNumber: state.pilotNumber
		}
		this.saveData(data);

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/trunk/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let redirectToTrunkManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'Request Failed!! Please try again') {
					Notify.notify(res.message);
					redirectToTrunkManagement = false;
				} else {
					redirectToTrunkManagement = true;
					Notify.notify(res.message);

				}
				this.setState({
					redirectToTrunkManagement: redirectToTrunkManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating Trunk', err)
				Notify.notify('Error in Updating Trunk');
			});

	}

	render() {
		if (this.state.redirectToTrunkManagement === true) {
			return <Redirect to={'/trunk-list/'} />
		}

		const dialerList = cloneDeep(this.state.dialerList);

		let dialerDropdown = '';
		if (!isEmpty(dialerList) && (!('error' in dialerList))) {
			dialerDropdown = Array.isArray(dialerList) ? dialerList.map((obj, index) => {
				return <option value={obj.dialerId} key={`dialerid${index}`} >{obj.dialerName}</option>
			}) : <option value="" >No Dialer found</option>

		} else {
			dialerDropdown = <option value="" >No Dialer found</option>
		}

		return (

			<Form method="post" onSubmit={this.updateTrunk}>
				<Row>
					<Col sm={12}>
					<Card>
						<Card.Header>Update Trunk</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Trunk Name</Form.Label>
											<Form.Control required name="trunkName" type="text" placeholder="Trunk Name" onChange={e => this.setvalue(e)} value={this.state.trunkName} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
										<Form.Label>Pilot Number</Form.Label>
											<Form.Control required name="pilotNumber" type="text" placeholder="Pilot Number" onChange={e => this.setvalue(e)} value={this.state.pilotNumber} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Dialer</Form.Label>
											<Form.Control required name="dialerId" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.dialerId}>{this.state.dialerName}</option>
												{dialerDropdown}
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Channels</Form.Label>
											<Form.Control required name="channels" type="text" placeholder="Channels" onChange={e => this.setvalue(e)} value={this.state.channels} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>CPS</Form.Label>
											<Form.Control required name="cps" type="text" placeholder="CPS" onChange={e => this.setvalue(e)} value={this.state.cps} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Update Trunk</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateTrunk;