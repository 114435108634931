import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Fetcher } from '../../Helpers/fetcher.js';
import { Notify } from '../../Helpers/notification.js';
import { createBrowserHistory } from 'history';
import "./index.css";

const STATE_RENDER_USERNAME = 1;
const STATE_RENDER_OTP = 2;
const STATE_RENDER_PASSWORD = 3;

class ResetPwd extends Component {
	
	constructor(props) {
		super(props);
		this.history = createBrowserHistory({forceRefresh:true})
		this.state = {
			username: '',
			otp: '',
			stateRender: STATE_RENDER_USERNAME,
			password: '',
			confirmPassword: '',
			uid: undefined
		}
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	sendOTP = (username, otp) => {

		const data = {username, otp}
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/verify/otp', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
		.then(res => {
			if (res.message === 'Parameters Incorrect') {
				Notify.notify('Something went wrong');
			} else if(res.message === 'OTP Verification Successfull'){
				Notify.notify(res.message);
				this.setState({'stateRender': STATE_RENDER_PASSWORD, 'uid': res.userId});
				window.open('','_self').close();
			} 
			else if(res.message === 'Please enter correct OTP'){
				Notify.notify(res.message);
			}
			else {
				Notify.notify('Something went wrong');
			}
		})
		.catch(err => {
			console.log('Error in ResetPwd', err);
		});

	}

	resetPwd = password => {
		const data = {password, userId: this.state.uid}
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/user/pwd/reset', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
		.then(res => {
			if (res.message === 'Parameters Incorrect') {
				Notify.notify('Something went wrong');
			} else if(res.message === 'Password Reset Successfully'){
				Notify.notify(res.message);
				window.open('','_self').close();
				this.history.push('/');
			} else {
				Notify.notify('Something went wrong');
			}
		})
		.catch(err => {
			console.log('Error in ResetPwd', err);
		});

	}

	getOtp = username => {

		const data = {
			username: username
		}
	
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/sendOtp/number', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Something went wrong');
				} else if(res.message === 'OTP Sent to your registered Mobile number'){
					Notify.notify(res.message);
					this.setState({'stateRender': STATE_RENDER_OTP, 'uid': res.userId});
					window.open('','_self').close();
				} else if(res.message === 'Invalid Username'){
					Notify.notify(res.message);
				} else {
					Notify.notify('Something went wrong');
				}
			})
			.catch(err => {
				console.log('Error in ResetPwd', err);
			});

	}

	submitForm = (e) => {
		
		e.preventDefault();

		const username = this.state.username.trim();
		const otp = this.state.otp.trim();
		const password = this.state.password.trim();
		const confirmPassword = this.state.confirmPassword.trim();

		if (username === '') {
			Notify.notify('Please enter Username');
		}

		if(this.state.stateRender == STATE_RENDER_USERNAME){
		
			this.getOtp(username);
		
		}else if(this.state.stateRender === STATE_RENDER_OTP){
			
			if (otp === '') {
				Notify.notify('Please enter OTP');
			}else{
				this.sendOTP(username, otp);
			}
		
		}else if(this.state.stateRender === STATE_RENDER_PASSWORD){
		
			if (password === '')
				Notify.notify('Please enter password');
			else if(confirmPassword === '')
				Notify.notify('Please enter confirm password');
			else if(confirmPassword !== password)
				Notify.notify('Password & Confim Password don\'t match');
			else
				this.resetPwd(password);

		}

		
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}


	render() {
		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="4">
							<Card className="p-4">
								<Card.Header>Reset Password</Card.Header>
								<Card.Body>
									<Form method="post" onSubmit={this.submitForm}>
										<div className="login-logo text-center">
											{this.props.app.logo && <img className="navbar-brand-full" src={this.props.app.logo} alt="CoreUI Logo" />}
										</div>

										<p className="text-muted text-center"></p>
										<InputGroup className="mb-3">
											<div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-user"></i></span></div>
											<Form.Control required className="field-username" name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} disabled={this.state.stateRender !== STATE_RENDER_USERNAME} autoComplete="new-username" />
										</InputGroup>
										
										{
											this.state.stateRender === STATE_RENDER_OTP
											?
											<>
												<p className="text-muted text-center"></p>
												<InputGroup className="mb-3">
													<div className="input-group-prepend" for="otp"><span className="input-group-text"><i className="fa fa-mobile"></i></span></div>
													<Form.Control required className="field-otp" name="otp" type="number" placeholder="OTP" onChange={e => this.setvalue(e)} value={this.state.otp} autoComplete="new-otp" />
												</InputGroup>
											</>
											:
											<></>
										}

										{
											this.state.stateRender === STATE_RENDER_PASSWORD
											?
											<>
													<Form.Group className="form-group" >
														<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} />
													</Form.Group>
													<Form.Group className="form-group" >
														<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
													</Form.Group>
											</>
											:
											<></>
										}

										<Row>
											<Col xs="3">
												<Button type="submit" color="primary" className="text-center px-4 btn btn-primary btn-round">Submit</Button>
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>

			</div>
		);
	}
}

export default ResetPwd;