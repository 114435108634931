import React from 'react';
import AddVoiceMailCampaign from './AddVoiceMailCampaign';
import ListVoiceMailCampaign from './ListVoiceMailCampaign';
import AddVoiceFile from './AddVoiceFile';
import ListVoiceFiles from './ListVoiceFiles';
import { Route, Switch } from "react-router-dom";
const Campaign = (props) => {
	const urlPrefix = props.app.urlPrefix;
	return (

		<div className="">
			<Switch>
				<Route path={`${urlPrefix}/add-voicemail-campaign`} ><AddVoiceMailCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/voicemail-campaign-summary`} ><ListVoiceMailCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/add-voice-files`} ><AddVoiceFile app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-files`} ><ListVoiceFiles app={props.app} /></Route>
			</Switch>
		</div>

	);
}

export default Campaign;
