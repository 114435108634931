import React from 'react';
import { Link } from 'react-router-dom'
import './index.css';
const SideNav = (props) => {
	let urlPrefix;
	if (props.app.mode === 'dev') {
		urlPrefix = '/build';
	} else {
		urlPrefix = '';
	}
	return (
		<div className="sidebar">
			<div className="scrollbar-container sidebar-nav">
				{props.app.role === 'ROLE_USER' && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
				
					<li className="nav-title">Voice Mail Campaigns</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voicemail-campaign`}><i className="fa fa-plus-circle"></i> Compose Voice Mail</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voicemail-campaign-summary`}><i className="fa fa-table"></i> Voice Mail Summary</Link></li>
					
					<li className="nav-title">Reports</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/download-report`}><i className="fa fa-table"></i> Download Report</Link></li>
					
					<li className="nav-title">Voice Library</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-files`}><i className="fa fa-plus-circle"></i> Add Voice File</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="fa fa-table"></i> View Voice Files</Link></li>
				</ul>}

				{(props.app.role === 'ROLE_SADMIN') && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-title">User Management</li>
					{/* <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="fa fa-plus-circle"></i> Create User </Link></li> */}
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="fa fa-table"></i> User List</Link></li>
					<li className="nav-title">CLI Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/upload-cli`}><i className="fa fa-plus-circle"></i> Upload CLI</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/cli-list`}><i className="fa fa-table"></i> CLI List</Link></li>
				</ul>}

			</div>
		</div>
	);

}

export default SideNav;
