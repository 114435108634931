import React, { Component } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "./index.css";

class ListVoiceCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignlist: [],
			selectedCampaignId: 0,
			isButtonDisabled: [],
			confirmStop: false,
			confirmPause: false,
			confirmResume: false,
			confirmRetry: false,
			loading: false
		}
		//<Route path={`${urlPrefix}/campaign-summary`} ><ListCampaign app={props.app} /></Route>
	}
	componentDidMount() {
		this.getCampaigns();
		this.refresh();
	}

	componentWillUnmount() {
		// Clear the interval right before component unmount
		clearInterval(this.interval);
	}

	refreshPage = () => {
		this.setState({ loading: true });
		//window.location.reload();

		setTimeout(() => {
			window.location.reload(false);
		}, 500);
	}

	refresh(){

		this.interval = setInterval( () => {
			this.getCampaigns();
		}, 10000);

	}

	getCampaigns = () => {
		const userId = this.props.app.userId;
		Fetcher.get(this.props.app.apiPath + '/api/voicemail/campaign/' + userId)
			.then(res => {
				this.setState({
					campaignlist: res,
					confirmStop: false,
					confirmPause: false,
					confirmResume: false,
					confirmRetry: false
				})
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });
	}

	downloadReport = (index, campaignId, type) => {
		const data = {
			"campaignId": campaignId,
			"reprtType": type
		};
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/report/download', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				alert(res.message);
				//document.getElementById('report-' + index).disabled = false;
				//document.getElementById('report-' + index).innerHTML = "Download";
				window.open(this.props.app.reportPath + '/reports/' + campaignId + '.zip', 'Download');
			})
			.catch(err => { console.log('Error in Downloading Report', err) });
	}

	downloadPending = (index, campaignId) => {
		const data = {
			"campaignId": campaignId,
			"reportType": 'pending_data'
		};
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/report/pending', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				//let csvRows = res.join('\n');
				let csvRows = res.map(item => item.substring(0, 10)).join('\n');
				// Create a Blob and initiate the download
				const blob = new Blob([csvRows], { type: 'text/csv' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'pending_data_' + campaignId + '.csv';
				link.click();
			})
			.catch(err => {
				Notify.notify('Something went wrong');
				console.log('Error in Downloading Report', err);
			});
	}

	generateReport = (index, campaignId, reportType) => {
		const data = {
			"campaignId": campaignId,
			"reportType": reportType
		};
		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/report/generate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {

				alert(res.message);
				this.getCampaigns();

				//document.getElementById("greport-" + index).disabled = true;
				//document.getElementById('greport-' + index).innerHTML = "Preparing";

			})
			.catch(err => { console.log('Error in Generating Report', err) });
	}

	confirmStop = (status, campaignId) => {
		this.setState({
			confirmStop: status,
			selectedCampaignId: campaignId
		});
	}

	confirmPause = (status, campaignId) => {
		this.setState({
			confirmPause: status,
			selectedCampaignId: campaignId
		});
	}

	confirmResume = (status, campaignId) => {
		this.setState({
			confirmResume: status,
			selectedCampaignId: campaignId
		});
	}

	confirmRetry = (status, campaignId) => {
		this.setState({
			confirmRetry: status,
			selectedCampaignId: campaignId
		});
	}

	stopCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/campaign/stop', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Stopping', err);
			});
	}

	pauseCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/campaign/pause', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Pause', err);
			});
	}

	resumeCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId,
			userId: this.props.app.userId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/campaign/resume', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Resume', err);
			});
	}

	retryCampaign = () => {
		const userId = this.props.app.userId;
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId,
			userId: userId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/voicemail/campaign/retry', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Retry', err);
			});
	}

	onResumeCampaign(campaign_id, is_status_active){
		if(is_status_active){
			this.setState({ selectedCampaignId: campaign_id });
			this.resumeCampaign();
		}
	}

	onPauseCampaign(campaign_id, is_status_active){
		if(is_status_active){
			this.setState({ selectedCampaignId: campaign_id });
			this.pauseCampaign();
		}
	}

	onStopCampaign(campaign_id, is_status_active){
		if(is_status_active){
			this.setState({ selectedCampaignId: campaign_id });
			this.stopCampaign();
		}
	}

	render() {

		const campaigns = this.state.campaignlist;
		const columns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true,
				csvExport: false
			},
			{
				dataField: 'campaignId',
				text: 'Campaign Id',
				sort: false,
				hidden: false,
				csvExport: true
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				csvExport: false
			}, {
				dataField: 'name',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				hidden: true,
				csvExport: true
			}, 
			// {
			// 	dataField: 'campaignstatus',
			// 	text: 'Campaign Status',
			// 	sort: true,
			// 	searchable: true
			// }, 
			// {
			// 	dataField: 'templateId',
			// 	text: 'Campaign Type',
			// 	sort: true,
			// 	searchable: true
			// }, 
			{
				dataField: 'scheduleTime',
				text: 'Start Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'endTime',
				text: 'End Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'numbersUploaded',
				text: 'Total Numbers',
				sort: false,
				searchable: false
			}, {
				dataField: 'numbersProcessed',
				text: 'Progress',
				sort: false,
				searchable: false
			}, {
				dataField: 'callsSubmitted',
				text: 'Pending Calls',
				sort: false,
				searchable: false,
				csvExport: false
			}, {
				dataField: 'callsSubmittedCsv',
				text: 'Pending Calls',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			}, {
				dataField: 'callsConnected',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				csvExport: false
			}, {
				dataField: 'callsConnectedCsv',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			}, {
				dataField: 'status',
				text: 'Status',
				sort: false,
				searchable: false,
				csvExport: false
			}, {
				dataField: 'fullReportStatus',
				text: 'Action',
				sort: false,
				searchable: false,
				csvExport: false,
				style: {minWidth: '160px'}
			}];

		const products = Array.isArray(campaigns) ? campaigns.map((campaign, index) => {
			let template = '';

			switch (campaign.templateId) {
				case 0:
					template = 'Voice Mail';
					break;
				default:
					template = '';
					break;
			}
			let status = '';

			switch (campaign.campaignStatus) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
					break;
				default:
					status = '';
					break;
			}

			let action = [], statusArr = [];
			let retry = [];
			//let 
			action.push((campaign.fullReportStatus === '2' || campaign.fullReportStatus === undefined) && <button className='btn btn-primary' id={`greport-${index}`} key={`greport-${index}`} onClick={() => this.generateReport(index, campaign.campaignId, 'full')}>Generate Report</button>);
			statusArr.push(<div key={campaign.campaignId} className='campaign-status'><div><i title="Resume" className={`fa fa-solid fa-play ${status == 'Paused' ? '': 'disabled'}`} onClick={() => this.onResumeCampaign(campaign.campaignId, status == 'Paused')}></i> &nbsp; <i title="Pause" className={`fa fa-solid fa-pause ${status == 'Running' ? '': 'disabled'}`} onClick={() => this.onPauseCampaign(campaign.campaignId, status == 'Running')}></i> &nbsp; <i title="Stop" className={`fa fa-solid fa-stop ${status == 'Running' ? '': 'disabled'}`} onClick={() => this.onStopCampaign(campaign.campaignId, status == 'Running')}></i></div>{status}</div>);

			let ccDownload = [];
			ccDownload.push(campaign.callsConnected);
			ccDownload.push(' ');
			if (campaign.callsConnected > 0) {
				if (campaign.ccReportStatus === '2' || campaign.ccReportStatus === undefined) {
					ccDownload.push(<a data-tip="" key={`${index}`} className="download-report" onClick={(e) => { this.generateReport(true, campaign.campaignId, 'connected_calls') }} href="#"><i className="fa fa-download"></i></a>);
				}
			}
			let ccPending = [];
			ccPending.push(campaign.numbersUploaded - campaign.numbersProcessed);
			ccDownload.push(' ');
			ccPending.push(<a data-tip="" key={`${index}-pending`} className="download-report" onClick={(e) => { this.downloadPending(true, campaign.campaignId) }} href="#"><i className="fa fa-download"></i></a>);

			const numbersProgressed = Math.floor(campaign.numbersProcessed*100/campaign.numbersUploaded);

			return {
				id: index + 1,
				campaignId: campaign.campaignId,
				name: campaign.campaignName,
				campaignName: campaign.campaignName,
				campaignstatus: status,
				templateId: template,
				scheduleTime: campaign.scheduleTime,
				endTime: campaign.endTime,
				numbersUploaded: campaign.numbersUploaded,
				numbersProcessed: <ProgressBar title={`${numbersProgressed}%`} label={`${numbersProgressed}%`} now={numbersProgressed} variant={numbersProgressed == 100 ? 'success' : 'warning'} />,
				callsSubmitted: (campaign.campaignStatus === 7 || campaign.campaignStatus === 9) ? campaign.numbersUploaded - campaign.numbersProcessed : campaign.numbersUploaded - campaign.numbersProcessed,
				callsSubmittedCsv: campaign.numbersUploaded - campaign.numbersProcessed,
				callsConnected: (campaign.campaignStatus === 2) ? campaign.callsConnected : campaign.callsConnected,
				callsConnectedCsv: campaign.callsConnected,
				status: statusArr,
				fullReportStatus: action,
			};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'scheduleTime',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
		};

		return (
			<Card>
				<Card.Header>Voice Campaign Summary</Card.Header>
				<Card.Body>
					<div className="campaign-list-table-cont">
						{/* <h3>Campaign Summary</h3> */}
						{/*{!isEmpty( this.state.campaignlist ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{({
								paginationProps, paginationTableProps
							}) => (<ToolkitProvider
								keyField="id"
								data={products}
								columns={columns}
								search
								bootstrap4
								exportCSV={{
									fileName: 'campaign_summary.csv'
								}}
							>
								{props => (<div className="">
									Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
									<span className="search-cont"><SearchBar {...props.searchProps} /></span>
									<ClearSearchButton className="btn-primary" {...props.searchProps} />
									<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
									<Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={this.refreshPage}>Refresh</Button>
									<hr />
									<BootstrapTable bordered={true} hover defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="w-auto campaignlist-table" {...props.baseProps} {...paginationTableProps} />
									<PaginationListStandalone {...paginationProps} />
								</div>)}
							</ToolkitProvider>)}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.confirmStop} onHide={() => this.confirmStop(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Stop Campaign
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to stop this campaign?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmStop(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.stopCampaign} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

					<Modal size="md" show={this.state.confirmPause} onHide={() => this.confirmPause(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Pause Campaign
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to pause this campaign?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmPause(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.pauseCampaign} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

					<Modal size="md" show={this.state.confirmResume} onHide={() => this.confirmResume(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Resume Campaign
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to resume this campaign?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmResume(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.resumeCampaign} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

					<Modal size="md" show={this.state.confirmRetry} onHide={() => this.confirmRetry(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Retry Campaign
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to retry this campaign?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmRetry(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.retryCampaign} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>


					{(this.state.loading === true) && <div className="fetching-data"><img alt="" src="/assets/loading.gif" /></div>}
				</Card.Body>
			</Card>
		);
	}
}


export default ListVoiceCampaign;